import './index.css';

import { MatomoProvider, createInstance } from 'matomo-react'

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const instance = createInstance({
  urlBase: 'https://matomo.advoxel.com',
  siteId:2,
  trackerUrl: 'https://matomo.advoxel.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://matomo.advoxel.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
  permanentTitle: "VoxelScope", // optional, always use this title for tracking, ignores document.title. Useful for SPAs.
  permanentHref: '/', // optional, always use this href for tracking, ignores window.location.href. Useful for SPAs.
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: 15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: process.env.NODE_ENV==="development"?false:true,
    setRequestMethod: 'POST',
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <App />
      </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
